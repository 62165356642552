@import '../../sass/variables.scss';

.navBar {
    .title {
        color: $color-white;
    }

    > div {
        height: inherit;
    }

    .logo {
        position: relative;
        height: 75px;
        width: 75px;
        min-width: 75px;

        svg {
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    &__mountain-icon {
        position: absolute;
        top: -10px !important;
        left: 5px !important;
    }
}
