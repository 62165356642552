@import '../../../sass/variables.scss';

.modal {
    min-width: 300px;
    min-height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
    border-radius: 4px;
    padding: 10px;
    box-sizing: border-box;

    &__header-row {
        display: flex;
        align-items: flex-start;
        font-weight: 400;
    }

    &__close-icon {
        font-size: 21pt;
        cursor: pointer;
        margin-left: auto;
    }

    &__background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 998;
        height: 100vh;
        width: 100vw;
        max-width: 100%;
        background-color: black;
        opacity: 0.35;
    }
}
