.theme-slider {
    .thumb {
        height: 29px;
        width: 29px;
        background-color: white;
        border-radius: 100%;
        position: relative;
        bottom: 4px;
        right: 4px;

        svg {
            position: relative;
            top: 1px;
            left: 1.5px;
        }
    }
}