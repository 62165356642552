@import '../../sass/mixins/media.scss';

main {
    height: 90vh;
    position: relative;

    @include phone {
        height: 82vh;
    }

    .carousel-container {
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .map-container {
        height: inherit;
        display: flex;
        position: relative;

        &__map {
            height: inherit;
            width: 100%;

            &--pointer {
                > div {
                    > div {
                        cursor: pointer !important;
                    }
                }
            }
        }

        &__overlay {
            min-height: 100px;
            width: 250px;
            position: absolute;
            top: 30px;
            right: 50px;
        }
    }

    .main__loader {
        z-index: 100000;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
