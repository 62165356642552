$phone-width: 500px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin phone {
    @media (max-width: #{$phone-width}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}
