@import '../../../sass/variables.scss';

.histogram {
    display: flex;
    
    &__bin {
        position: relative;

        &:not(:first-child) {
            margin-left: 2px;
        }
    }


    &__bar {
        position: absolute;
        width: inherit;
        left: 2px;
        bottom: 0;

        &--transparent {
            opacity: 0.35;
        }
    }
}
