@import '../sass/variables';

html {
    height: 100vh;
    width: 100vw;
    font-family: $font-default;
    font-weight: 100;
    font-size: 13pt;
    background-color: $color-gray;
    max-width: 100%;

    body, #root, .app {
        height: inherit;
    }

    .app {
        display: flex;
        flex-direction: column;
        position: relative;
    }
}