@import '../../sass/mixins/media';

.uac-warning {
    padding: 2rem;
    text-align: center;
    max-width: 450px;

    &__body {
        display: flex;
        align-items: center;

        flex-direction: column;

        svg {
            width: 125px;
            height: 125px;
        }
    }

    &__warning {
        font-size: 1.75rem;
    }

    &__title {
        font-weight: 500 !important;
        margin-bottom: 20px !important;
    }
}
