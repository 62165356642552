@import '../../../../sass/variables';
@import '../../../../sass/mixins/media';

:root {
    --filter-padding: 2em;
    --filter-width: 370px;
    --filter-width-mobile: 100vw;
    --filter-padding-mobile: 0.75em;
}

.filters {
    width: var(--filter-width);
    height: 100%;
    background-color: $color-gray;
    padding: var(--filter-padding);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;

    @include phone {
        width: var(--filter-width-mobile);
        padding: 10px;
    }

    &__filter {
        position: relative;

        &:not(:first-child) {
            margin-top: 1rem;
        }
    }

    &__elevation {
        .MuiSlider-markLabel {
            font-size: 0.85rem;
        }
    }

    &__width {
        .MuiSlider-markLabel {
            font-size: 0.75rem;
        }
    }


    &--collapsed {
        --collapsed-filter-width: 50px;
        width: var(--collapsed-filter-width);
        padding: 0;
        padding-top: var(--filter-padding);

        .collapse-container {
            justify-content: center;

            svg {
                margin-left: 2px !important;
            }
        }
    }

    .collapse-container {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        .caret {
            cursor: pointer;
            font-weight: 100;
            margin-left: auto;
            margin-right: 15px;
            transform: scale(3);
            font-size: unset;
        }

        .filter {
            transform: scale(2.7);
            font-size: unset;
        }
    }
}
