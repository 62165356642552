/* COLORS */
$color-primary: #3b6474;
$color-primary-light: #8dabba;
$color-primary-dark: #33505d;

$color-secondary: #38BBED;
$color-secondary-light: #ffffff;
$color-secondary-dark: #aeaeae;

$color-text-on-primary: #000000;
$color-text-on-secondary: #000000;

$color-gray: #9e9e9e;
$color-orange: #FF7D4B;
$color-aqua: #54C3BC;
$color-magenta: #E44A92;
$color-white: #fff;

/* FONTS */
$font-default: Roboto-Regular, "Segoe UI";
